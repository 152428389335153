import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import "./index.css";

// Import material design icons helper css
import "vue-material-design-icons/styles.css";
// Import material design icons
import ArrowDownCircle from "vue-material-design-icons/ArrowDownCircle.vue";
import EmoticonHappyOutline from "vue-material-design-icons/EmoticonHappyOutline.vue";
import EmoticonNeutralOutline from "vue-material-design-icons/EmoticonNeutralOutline.vue";
import EmoticonSadOutline from "vue-material-design-icons/EmoticonSadOutline.vue";

const app = createApp(App);
app.use(store);
app.component("arrow-down-circle-icon", ArrowDownCircle);
app.component("emoticon-happy-outline-icon", EmoticonHappyOutline);
app.component("emoticon-neutral-outline-icon", EmoticonNeutralOutline);
app.component("emoticon-sad-outline-icon", EmoticonSadOutline);
app.mount("#app");
