<template>
  <div class="h-screen overflow-scroll rs-hide-scrollbars bg-black bg-opacity-40 text-white tracking-wider">
    <div class="mt-12 sm:mx-auto max-w-lg px-6 pb-12">
      <div class="flex flex-col mb-6">
        <div class="text-3xl font-semibold">MPark Mastering</div>
      </div>
      <div class="px-6 pt-4 pb-6 mb-6 bg-black bg-opacity-60 text-sm sm:text-lg rounded-xl">
        <div class="text-2xl mb-4">
          <div class="mb-2">Neal Ostrovsky</div>
          <span class="font-thin mb-4 text-white text-opacity-70">
            is an audio engineer specializing in mixing and mastering music for artists and video productions.
          </span>
        </div>
        <div
          class="px-6 py-4 bg-white bg-opacity-10 cursor-pointer rounded-lg"
          @click="this.flipInfoDetail"
          v-if="this.infoDetail"
        >
          <div class="mb-4">
            A Berklee College of Music graduate, Neal started as a professional drummer in a major label band
            who engineered and co-produced their records. He has also worked as an artist with some of the
            most highly regarded producers and engineers in the music industry.
          </div>
          <div class="mb-4">
            For the past 20+ years he has helped countless bands and artists record, mix and master their
            music working out of B-Side Audio and MPark Mastering.
          </div>
          <div class="mb-4">
            Neal’s first hand experience with all aspects of the creative process allows him to bring a
            uniquely musical approach to his work.
          </div>
        </div>
        <div
          class="px-6 py-4 bg-white bg-opacity-10 hover:bg-opacity-20 text-center text-gray-300 hover:text-white cursor-pointer rounded-lg"
          @click="this.flipInfoDetail"
          v-else
        >
          More
        </div>
      </div>
      <div class="video-container mb-4">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/1RMUiiwvoi4"
          title="MPark Mastering - Do your tracks sound as good as you imagined?"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="video-container mb-4">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/g5BcuIhTPk8"
          title="MPark Mastering - Studio Tour - Workflow"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="px-6 pt-4 pb-6 mb-4 bg-black bg-opacity-60 text-sm sm:text-lg rounded-xl">
        <div class="font-semibold mb-2">Clients</div>
        <div class="flex mb-4">
          <div class="w-1/2">
            <div class="font-thin">Warner Brothers</div>
            <div class="font-thin">Atlantic</div>
            <div class="font-thin">Mews 5</div>
          </div>
          <div class="w-1/2">
            <div class="font-thin">Rough Trade</div>
            <div class="font-thin">Bloodshot</div>
            <div class="font-thin">Wholesome Wax</div>
          </div>
        </div>
        <div
          class="cursor-pointer rounded-lg flex flex-wrap"
          @click="this.fliClientDetail"
          v-if="this.clientDetail"
        >
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Urge Overkill</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">The Webb Brothers</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Hushdrops</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Plush</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Caviar</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Local H</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Kelsey Wild</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">A.J. Kluth</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Air this Side of Caution</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Jennifer Hall</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Deanna Devore</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">The Hoyle Brothers</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">The House of Soviet Eyewear</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Daniel Carlson</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Kinky Kove</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">The M's</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">The Changes</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">The Assassins</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Cameron McGill</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Sono</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Bryan Harrell</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Ness</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Blake Zweig</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Thee Arthur Layne</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Tiny Speakers</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Ultrascarlet</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Zack</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">9 Day Wonder</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Lola Balatro</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Slings & Arrows</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Catfish Haven</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Matt Ulery</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Eastern Blok</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Tubring</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Farewell Captain</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Sisero</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Panther Style</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Joy Poppers</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">The It's!</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Legacy Productions</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Cisco Pike</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Oh My God</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Darren Spitzer</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Five Foot Nine</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Erin McDougal</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Electric Airlines</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Mickey Soldo</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Penthouse Sweets</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Matt Hopper</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Siderunners</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">The Zerostars</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">The Bonmots</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">The Eric Chial 2</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Statutes of Liberty</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">The Damnwells</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Idle Hands</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Scott Hunt</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Matt Lenny</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">John Roothanne Trio</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Rex Hardy Jr</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Roy Hamilton</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">J Shep</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Fourth of July</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">John Milne</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Fetch</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Urn</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Dovetail joint</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Vicarious</div>
          <div class="px-2 py-0.5 bg-mpark-purple-rg mr-1 mb-1 rounded-md">Cellar Steps</div>
        </div>
        <div
          class="px-6 py-4 bg-white bg-opacity-10 hover:bg-opacity-20 text-center text-gray-300 hover:text-white cursor-pointer rounded-lg"
          @click="this.fliClientDetail"
          v-else
        >
          More
        </div>
      </div>
      <div class="flex justify-between text-sm">
        <a class="text-white text-opacity-40 hover:text-opacity-90" href="mailto:contact@mparkmastering.com"
          >contact@mparkmastering.com</a
        >
        <a class="hidden text-white text-opacity-40 hover:text-opacity-90" href="tel:+17732976487"
          >1+ (773) 297-6487</a
        >
      </div>
    </div>
    <div
      class="bg-cover bg-center fixed top-0 left-0 w-full h-full"
      v-bind:style="{ backgroundImage: 'url(/img/controlroom_hero_1.jpg)' }"
      style="z-index: -1000"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      infoDetail: false,
      clientDetail: false
    }
  },
  methods: {
    flipInfoDetail() {
      this.infoDetail = !this.infoDetail
    },
    fliClientDetail() {
      this.clientDetail = !this.clientDetail
    }
  }
}
</script>

<style lang="scss">
/* Hide scrollbar for Chrome, Safari and Opera */
.rs-hide-scrollbars::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.rs-hide-scrollbars {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Youtube video fill container */
.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
